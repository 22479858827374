<template>
  <div class="bg-white min-h-screen">
    <SidebarComponent :isOpen="sidebarOpen" @toggle="toggleSidebar" />
    <header class="bg-white shadow-md fixed top-0 left-0 right-0 z-20">
      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div class="flex justify-between items-center py-3 md:justify-start md:space-x-10">
          <div class="flex justify-start lg:w-0 lg:flex-1">
            <h1 class="text-sm sm:text-2xl md:text-3xl semi-font-bold text-orange-600 tracking-wide uppercase truncate">
              Discover Unforgettable Events
            </h1>
          </div>
          <SidebarToggle @toggle="toggleSidebar" />
        </div>
      </div>
    </header>
    
    <main v-if="isLoaded" class="pt-12"> <!-- Content is displayed only when isLoaded is true -->
      <HeroComponent @register="goToEventDetail" />
      
      <section class="mt-8 py-4 px-4 sm:px-6 lg:px-8">
        <h2 class="text-2xl font-bold mb-6 text-gray-800">Upcoming Events</h2>
        <EventListComponent @event-click="goToEventDetail" />
      </section>
    </main>

    <LoadingScreen v-else /> <!-- Display the loading screen until the content is loaded -->
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import HeroComponent from '../components/HeroComponent.vue';
import EventListComponent from '../components/EventListComponent.vue';
import SidebarComponent from '../components/SidebarComponent.vue';
import SidebarToggle from '../components/SidebarToggle.vue';
import LoadingScreen from '../components/LoadingScreen.vue'; // Import the loading screen component

export default {
  name: 'HomeView',
  components: {
    HeroComponent,
    EventListComponent,
    SidebarComponent,
    SidebarToggle,
    LoadingScreen
  },
  setup() {
    const router = useRouter();
    const sidebarOpen = ref(false);
    const isLoaded = ref(false); // State to track if content is loaded

    const goToEventDetail = (eventId) => {
      router.push({ name: 'EventDetail', params: { id: eventId } });
    };

    const toggleSidebar = () => {
      sidebarOpen.value = !sidebarOpen.value;
    };

    onMounted(() => {
      // Simulate loading time
      setTimeout(() => {
        isLoaded.value = true;
      }, 2000); // Adjust the timeout as needed
    });

    return {
      sidebarOpen,
      goToEventDetail,
      toggleSidebar,
      isLoaded
    };
  }
};
</script>

<style scoped>
/* Add any necessary styles for the loading screen */
</style>
